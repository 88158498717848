import { getAttributesLabel } from 'components/common/attributes-label/helpers';
import { AttributesLabel } from 'components/common/attributes-label/styles';
import Button from 'components/common/button';
import LoadingText from 'components/common/loading-text';
import SiteLink from 'components/common/site-link';
import ProductCarousel from 'components/layouts/product/carousel';
import { ThumbnailBreakpoints } from 'components/layouts/product/carousel/helpers';
import Nutshells from 'components/layouts/product/nutshells';
import { PriceLabel } from 'components/layouts/product/sidebar/styles';
import { getProductUrl } from 'utils/commercetools/product';
import formatPrice from 'utils/formatPrice';
import { toPlainText } from 'utils/sanity';

import {
  MobileModalSubHeading,
  DesktopModalSubHeading,
  QuickBuyModalProductInfo,
  QuickBuyCarouselWrapper,
  AddToBasketWrapper,
  NutshellsMobileRow,
} from './styles';
import { IGetModalBodyArgs } from './types';

const maxLength = { mobile: 70, desktop: 140 };

export function getSubHeadingComponents(text: string) {
  const firstSentence = getFirstSentence(text);

  const mobileText =
    firstSentence.length <= maxLength.mobile ? (
      firstSentence
    ) : (
      <span>{firstSentence.substring(0, maxLength.mobile - 1)}&#8230;</span>
    );

  const DesktopText =
    firstSentence.length <= maxLength.desktop ? (
      firstSentence
    ) : (
      <span>{firstSentence.substring(0, maxLength.desktop - 1)}&#8230;</span>
    );

  return [
    <MobileModalSubHeading key="mobile-subheading">
      {mobileText}
    </MobileModalSubHeading>,
    <DesktopModalSubHeading key="desktop-subheading">
      {DesktopText}
    </DesktopModalSubHeading>,
  ];
}

function getFirstSentence(text: string) {
  const afterFirstDotRegex = /\..+/;
  return text.replace(afterFirstDotRegex, '');
}

export function getModalBody({
  product,
  setIsModalOpen,
  postAddToBag,
  isAddingToBag,
  hasCookieAccepted,
}: IGetModalBodyArgs) {
  const title = product?.name;
  const subHeading = toPlainText(product?.whyWeLoveIt || []);
  const price = product?.price ? formatPrice(product?.price) : null;
  const linkToPDP = product ? getProductUrl(product) : null;
  const nutshells = product?.nutshells || null;

  const buttonElement = (
    <Button
      id="btn-quick-buy-add"
      width="full"
      variant="primary"
      onClick={() => {
        setIsModalOpen(false);
        postAddToBag();
      }}
      disabled={product?.bestOffer ? !product.bestOffer.isOnStock : true}
    >
      {product?.bestOffer?.isOnStock ? (
        isAddingToBag ? (
          <LoadingText text="Adding" alwaysShow />
        ) : (
          'Add to Basket'
        )
      ) : (
        'Out of Stock'
      )}
    </Button>
  );

  return (
    <>
      <QuickBuyCarouselWrapper>
        {product && (
          <ProductCarousel
            product={product}
            dottedThumbnailsOnly
            dottedThumbnailsBreakpoint={ThumbnailBreakpoints.BelowDesktop}
          />
        )}
      </QuickBuyCarouselWrapper>
      <QuickBuyModalProductInfo hasCookieAccepted={hasCookieAccepted}>
        {title && <h2>{title}</h2>}
        {subHeading && getSubHeadingComponents(subHeading)}
        {product && product.attributesRaw && (
          <AttributesLabel>
            {getAttributesLabel(product.attributesRaw)}
          </AttributesLabel>
        )}
        {price && <PriceLabel>{price}</PriceLabel>}
        {nutshells?.length ? (
          <NutshellsMobileRow>
            <Nutshells nutshells={nutshells} />
          </NutshellsMobileRow>
        ) : null}
        {linkToPDP && (
          <SiteLink
            underline
            href={linkToPDP}
            onClick={() => setIsModalOpen(false)}
          >
            View full product information
          </SiteLink>
        )}
        {buttonElement}
      </QuickBuyModalProductInfo>
      <AddToBasketWrapper hasCookieAccepted={hasCookieAccepted}>
        {buttonElement}
      </AddToBasketWrapper>
    </>
  );
}
