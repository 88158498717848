import styled from 'styled-components';

import { AttributesLabel } from 'components/common/attributes-label/styles';
import ResponsiveBox from 'components/common/responsive-box';
import SiteLink from 'components/common/site-link';
import theme from 'utils/theme';

import {
  IVariantPricingAndQuickBuyWrapperProps,
  IProductItemWrapperProps,
} from './types';

export const StyledResponsiveBox = styled(ResponsiveBox)({
  backgroundColor: theme.colors.white,
});

export const ProductDetails = styled.div({
  marginTop: '16px',
});

export const ProductName = styled.div({
  color: theme.colors.black,
  fontFamily: theme.fonts.body,
  fontWeight: theme.weights.bold,
  fontSize: '16px',
  marginTop: '16px',
});

export const ProductPrice = styled.div`
  color: ${theme.colors.darkGreen};
  font-weight: ${theme.weights.bold};
  margin-top: 8px;
`;

export const VariantPricingAndQuickBuyWrapper = styled.div<IVariantPricingAndQuickBuyWrapperProps>`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: relative;
  margin-top: 16px;

  @media (hover: hover) {
    min-height: ${(props) => (props.displayQuickBuyButton ? '50px' : '0px')};
  }

  ${ProductName}, ${ProductDetails} {
    margin-top: 0;
  }

  ${AttributesLabel} {
    color: ${theme.colors.grey};
    font-weight: ${theme.weights.bold};
    font-size: 1rem;
    margin-top: 8px;

    &:empty {
      margin-top: 0;
    }
  }

  ${ProductPrice} {
    margin-bottom: 16px;

    @media (hover: hover) {
      margin-bottom: 0;
    }
  }
`;

export const QuickBuyWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;

  @media (hover: hover) {
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    margin-top: 0px;
    opacity: 0;
  }
`;

const hoverStylesWithQuickBuyEnabled = `
  &:hover {
    ${ProductPrice}, ${ProductDetails}, span {
      opacity: 0;
    }
    ${QuickBuyWrapper} {
      opacity: 1;
    }
  }`;

export const Wrapper = styled.div<IProductItemWrapperProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: '16px';

  @media (hover: hover) {
    ${(props) =>
      props.displayQuickBuyButton ? hoverStylesWithQuickBuyEnabled : ''}
  }
`;

export const StyledSiteLink = styled(SiteLink)`
  display: inline-block;
  width: 100%;
`;

interface SkeletonTextProps {
  width: number;
}

export const SkeletonResponsiveBox = styled(ResponsiveBox)({
  backgroundColor: theme.colors.darkBg,
});

export const SkeletonText = styled.span<SkeletonTextProps>((props) => ({
  display: 'inline-block',
  backgroundColor: theme.colors.darkBg,
  color: 'transparent',
  width: '100%',
  maxWidth: props.width,
  userSelect: 'none',
}));

export const ColorWrapper = styled.div({
  marginTop: 8,
});

export const VariantDetails = styled.ul({
  color: theme.colors.grey,
  fontWeight: theme.weights.bold,
  listStyleType: 'none',
  marginTop: '8px',
  marginBottom: 0,
  padding: 0,
  li: {
    marginBottom: 4,
    '&:last-child': {
      marginBottom: 0,
    },
  },
});
